import React, { useEffect, useRef } from 'react'
import { Link, navigate, graphql } from "gatsby"
import Img from "gatsby-image"
import SEO from '../components/seo'
import { css } from "@emotion/core"
import styled from '@emotion/styled'
import Layout from "../components/layout"
import Masonry from 'react-masonry-css'
import { RichText } from "prismic-reactjs/src"
import { linkResolver } from "../utils/linkResolver"
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import _ from 'lodash'
import { getCursorFromDocumentIndex } from 'gatsby-source-prismic-graphql'

dayjs.extend(customParseFormat)

export const fragmentPostData = graphql` 
fragment PostData on PRISMIC_Post { 
  _meta {
    id
    uid
    type
    firstPublicationDate
    lastPublicationDate
    tags
  }
  date
  title
  excerpt
  category {
    ... on PRISMIC_Category {
      name
      _meta {
        id
        uid
        type
      }
    }
  }
  post_embed
  post_background
  post_image

  grid_portrait_image

  body {
    ... on PRISMIC_PostBodyText {
      type
      label
      primary {
        text
      }
    }
  }
} `;

export const query = graphql`
  query prismicPosts($limit: Int = 20, $after: String) {
    prismic {
      stickyPosts: allPosts(tags: "sticky" sortBy: date_DESC lang: "en-us") {
        edges {
          node {
            ...PostData
          }
        }
      }
      allPosts(first: $limit, after: $after, sortBy: date_DESC lang: "en-us") {
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        edges {
          node {
            ...PostData
          }
        }
      }
    }
  }
`
const postLimit = 20

const KateOfTheDayPost = ({ post }) => {

  if(!post.node.post_embed) { return null }

  return(
    <div css={css`margin-bottom: 95px; /*box-shadow: 0 0 16px rgba(0,0,0,.15);*/
    @media (max-width: 768px) {
      margin-bottom: 20px;
    }`}>
      <div css={css`background-color:#ff00ef; display: flex; flex-direction: column; align-items:center;justify-content: end; min-height: 240px;
      @media (max-width: 768px) {
        min-height: 170px;
      }
      `}><span css={css`
  font-family: 'Cormorant', Serif;
  font-size: 200px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.26;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  display: block;
  margin-bottom: 40px;
  @media (max-width: 768px) {
    font-size: 120px;
    margin-bottom: 20px;
  }
`}>Kate</span> <span css={css`
  font-family: 'Cormorant', Serif;
  display: block;
  font-size: 60px;
  font-weight: 600;
  font-style: italic;
  font-stretch: normal;
  line-height: 0.87;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  margin-bottom: 10px;
  @media (max-width: 768px) {
    font-size: 55px;
  }
`}>of the day</span></div>
      { (post.node.post_embed === undefined || post.node.post_embed === null) ? null :

        <div css={css`.instagram-media{max-width: 100% !important}`} dangerouslySetInnerHTML={ {__html: post.node.post_embed.html} }></div>
      }
    </div>
  )
}

const Embed = ({ post }) => {
  const { post_embed: embed } = post.node
  if(embed === 'undefined' || embed === null) { return null }
  
  function youtubeStyle() {
    if(post.node.post_image || post.node.grid_portrait_image) {
      return `
        position:absolute;top:0;left:0;z-index:50;width:100%;
      `
    } else {
      return null
    }
  }

  switch(embed.provider_name) {
    case 'YouTube':
      return(
        <div css={css`position:relative;padding-bottom:56.25%;${youtubeStyle()}`} >
          <div css={css`
          position: absolute; top:0; left:0; width: 100%; height: 100%; display: flex;   align-items: center;   justify-content: center; 
          iframe{ width: 100%; height: 100%; max-width: 1140px; max-height: 641px; 
          `} dangerouslySetInnerHTML={ {__html: embed.html} } />
        </div>
      )
    case 'Instagram':
        return(<div css={css`.instagram-media{max-width:100% !important;width:100%;`} dangerouslySetInnerHTML={ {__html: embed.html} } />)
    default:
      return null
  }
}

const PostImage = ({ post }) => {
  // const { post_image, post_imageSharp, grid_portrait_image, grid_portrait_imageSharp } = post.node
  const { post_image, grid_portrait_image } = post.node
  if (!post_image && !grid_portrait_image) { return null }
  // console.log(post_image)
  if (grid_portrait_image && !post.node._meta.tags.includes(`wide`)) {
    return(<img src={grid_portrait_image.url} />)
    // return(<Img fluid={grid_portrait_imageSharp.childImageSharp.fluid} /> )
  } else {
    return(<img src={post_image.url} />)
    // return(<Img fluid={post_imageSharp.childImageSharp.fluid} /> )
  }
}

const PostImageCopyright = ({ post }) => {
  return(
    (post.node.post_image === undefined || post.node.post_image === null) ? null :
    <div css={css`height: 27px;
    font-family: 'Cormorant', Serif;
    font-size: 17px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.59;
    letter-spacing: normal;
    text-align: right;
    color: rgba(0, 0, 0, 0.57);margin-right:16px; position: absolute; right:0; `}>{ post.node.post_image.alt }</div>
  )
}

const PostExcerpt = ({ post }) => {
  const { excerpt, body } = post.node
  let flatBody = {}
  if(Array.isArray(body)) {
    const bodyFiltered = body.filter(item => {
      if(item.type && item.type === "text") {
        return item.primary.text
      }
    })
    flatBody = _.flatten(bodyFiltered.map(item => item.primary.text))
  }
  return(
    (excerpt && excerpt[0].text.length > 0) ? RichText.asText(excerpt) : 
    Array.isArray(flatBody) ? `${RichText.asText(flatBody).substring(0, 60)} (...)` : null
  )
}

export const Post = ({ post }) => {

  const post_background = post.node.post_background || '#ffffff'

  if (post.node.category && post.node.category._meta.id === 'XXp02hEAACcAR0K-') {
    return (<KateOfTheDayPost post={post} />)
  }

  return (

    <div key={post.node._meta.id} css={css`position: relative;margin-bottom:95px;/*box-shadow: 0 0 16px rgba(0,0,0,.15);*/
    @media (max-width: 768px) {
      margin-bottom: 20px;
    }
    `}>
      <div css={css`position:relative;`} onClick={ () => navigate(linkResolver(post.node._meta)) }>
        <Embed post={post} />
        <PostImage post={post} />
      </div>
      <PostImageCopyright post={post} />
      <div css={css`padding: 60px; display: flex; flex-direction: column; align-items:center;justify-content: center;
      transition: background-color .25s ease-in-out;
      background-color: ${post_background};
      :hover{
        background-color: #fafafa;
      }
      @media (max-width: 768px) {
        padding: 40px 20px 60px 20px;
      }
      `}>
        <div css={css`
            margin-bottom: 67px;
            width: 100%;
            height: 27px;
            font-family: 'Libre Franklin', Sans-Serif;
            font-size: 14px;
            font-weight: 600;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.93;
            letter-spacing: 5px;
            text-align: center;
            color: #000000;
            text-transform: uppercase;
            @media (max-width: 768px) {
              font-size: 12px;
              line-height: 1.25em;
              margin-bottom: 20px;
            }
        `}>
        { (post.node.category === null) ? null : 
              <>
              <Link to={linkResolver(post.node.category._meta)} css={css`color: #000; text-decoration: none;`}>{post.node.category.name}</Link> | 
              </>
      } { dayjs(post.node.date.substring(0, post.node.date.length - 14), 'YYYY-MM-DD').format('DD. MMM YYYY') }</div>
      <Link to={linkResolver(post.node._meta)} css={css`
      color: #757575;
      text-decoration: none;
      display: block;
      max-width: 980px;
      margin: 0 auto;
      font-family:'Cormorant', Serif;
      font-weight: 400;
      font-size: 55px;          
      font-style: normal;          
      font-stretch: normal;          
      line-height: 1.11;          
      letter-spacing: normal;          
      text-align: center;
      strong{
        font-weight: 300;
        color: #000;
      }
      @media (max-width: 768px) {
        font-size: 20px;
      }
      `}>
        <strong>{RichText.asText(post.node.title)}</strong> <PostExcerpt post={post} />
      </Link>
    </div>
    </div>
  )
  
}

export default ({ data, prismic }) => {

  const limit = postLimit;
  const didMountRef = useRef(false);
  const [pageInfo, setPageInfo] = React.useState(data.prismic.allPosts.pageInfo)
  const [posts, setPosts] = React.useState(data.prismic.allPosts.edges)
  const onLoadMoreClick = () => setPage(page + limit);

  const [page, setPage] = React.useState(-1);
  useEffect(() => {
    if (!didMountRef.current) {
      didMountRef.current = true;
      return;
    }
    prismic
      .load({ query, fragments: [fragmentPostData], variables: { after: getCursorFromDocumentIndex(page) } })
      // .then(res => setPrismicPosts(res.data));
      .then(res => {
        setPageInfo(res.data.allPosts.pageInfo)
        return setPosts(posts => [...posts, ...res.data.allPosts.edges])
      })
      .catch(error => console.log('prismic error', error));
  }, [page]);

  // const posts = data.prismic.allPosts.edges
  // const stickyPosts = posts.filter(item => item.node._meta.tags.includes('sticky'))
  const stickyPosts =  data.prismic.stickyPosts.edges

  let chunks = []

  for (const [index, post] of [...stickyPosts, ...posts.filter(item => !item.node._meta.tags.includes('sticky'))].entries()) {
    // push wide array to chunk array
    if (post.node._meta.tags.includes(`wide`)) {
     chunks.push(post)
    } else {
      const lastChunkItem = chunks[chunks.length-1]
      // check if the last chunk item is array, so small post can get pushed to it
      if (Array.isArray(lastChunkItem)) {
        lastChunkItem.push(post)
      } else {
        // create new array with small post
        chunks.push([post])
      }
    }

  }

  return (
    <>
    <SEO title="Feldmann trommelt" titleTemplate="Feldmann trommelt" />
    <Layout>
      {
    chunks.map((item, index) => {
    if (Array.isArray(item)) {

      return(  <Masonry key={index} breakpointCols={{ default: 2, 768: 1 }} css={css`  
        @media (max-width: 768px) {
          padding: 20px;
        }
        @media (min-width: 768px) {
          
          display: flex;
          margin-left: -95px; /* gutter size offset */
          width: auto;
          padding: 0 95px;
          &:first-of-type{
            padding-top: 95px;
          }
          
          .column{
            padding-left: 95px;
            background-clip: padding-box;
          }
        }
        `}  columnClassName="column">
              {item.map((post, index) => <Post key={index} post={post} />)}
      </Masonry> )
    } else {
      return <Post key={index} post={item} />
    }
  })
  }
    { pageInfo.hasNextPage && <button disabled={!pageInfo.hasNextPage} onClick={onLoadMoreClick}
    css={css`
      border: none;
      margin: 16px auto;
      padding: 8px;
      font-size: 24px;
      background: none;
      width: 100%;
      text-align: center;
      font-family:'Cormorant', Serif;
      font-weight: 400;
      color: #333;
      cursor: pointer;
      display: inline-block;
    `}
    >
      Load More
    </button>}
   </Layout>
    </>
  )
}